require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("jquery")
require("bootstrap")

import Rails from "@rails/ujs";
window.Rails = Rails;

// Para poder usar jquery en archivo.js.haml
import $ from 'jquery';
global.$ = jQuery;
// segunda opcion probar 
window.jQuery = $;
window.$ = $;



// IMAGENES 
const importAll = (r) => r.keys().map(r)
importAll(require.context('../images', false, /\.(png|jpe?g|svg)$/));

import '../images/ioscor-logo.jpg'

//import '../varios/bootstrap-notify.min'
//import '../varios/notificaciones'
//import '../varios/instalar_app'
//import '../varios/compartir'




// GESTION IMAGES
require.context('../images/', true, /\.(gif|jpg|png|svg|eot|ttf|woff|woff2)$/i)


// GESTION CSS
require ('../stylesheets/application')





